import { motion } from "framer-motion";
import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import Anchor from "~/components/Anchor";
import Block, { BlockContent } from "~/components/Block";
import Divider from "~/components/Divider";
import { getCommonSliceProps } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { ThemeContext } from "~/lib/ThemeContext";
import theme from "~/styles/theme";

type PropType = {
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  color?: string;
  animateIn?: boolean;

  anchorText?: string;
  anchorPreText?: string;
  anchorId?: string;
  size?: number;
  withDivider?: boolean;
};

const variants = {
  out: { opacity: 0 },
  in: {
    opacity: 1,
    transition: {
      type: "tween",
      ease: [0.23, 1, 0.32, 1],
      duration: 2,
    },
  },
};

function AnchorSlice(props: PropType) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    color = currentTheme.foreground,
    backgroundColor = currentTheme.background,
    animateIn = false,

    anchorText,
    anchorPreText,
    anchorId,
    size,
    withDivider,
  } = props;

  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  let animate = inView ? "in" : "out";
  if (!animateIn) {
    animate = "in";
  }

  // NOTE: anchor works without text.. will only render the trigger

  return (
    <motion.div variants={variants} animate={animate}>
      <Block
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
        backgroundColor={backgroundColor}
        color={color}
        zIndex={theme.z.body}
      >
        <BlockContent ref={ref}>
          <Anchor id={anchorId} size={size} pre={anchorPreText} color={color}>
            {anchorText}
          </Anchor>
          {withDivider && anchorText && <Divider color={color} />}
        </BlockContent>
      </Block>
    </motion.div>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  const props = {
    ...getCommonSliceProps(slice),

    size: primary.size,
    withDivider: primary.divider_underneath === "Yes",
  };

  return props;
}

export default connectSlice(mapSliceToProps)(AnchorSlice);
